<template>
  <div class="d-flex" style="min-height: 400px;">
    <app-bar></app-bar>
    <v-container
      class="py-2 px-8"
      style="background-color: #f4f2f2;"
      fluid
    >
      <v-fade-transition mode="out-in">
        <router-view/>
      </v-fade-transition>
    </v-container>
  </div>
</template>

<script>
import { ApiAuth } from '@/api_utils/axios-base'
export default {
  data() {
    return {
    }
  },
  components: {
    AppBar: () => import('./DashBoardAppBar.vue')
  }, 
  created() {
  },
  methods: {
  }, 
}
</script>
